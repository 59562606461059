/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Image = ({ src, height, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {extension: {in: ["png", "jpg"]}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 2800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                aspectRatio
              }
            }
          }
        }
      }
    }
  `)

  const match = useMemo(() => (
    data.allFile.edges.find(({ node }) => src === node.relativePath)
  ), [ data, src ])

  return (
    <Img
      sizes={match.node.childImageSharp.fluid}
      fadeIn={false}
      loading='lazy'
      objectFit='contain'
      imgStyle={{objectFit: 'contain'}}
      {...props}
      style={height && {'--img-height' : height, width: `calc(var(--img-height) * ${match.node.childImageSharp.fluid.aspectRatio})`, height: 'var(--img-height)' }}
    />
  )
}

export default Image